export function createOverlay(contentMap) {
  return contentMap.overlay.value
}

export function createErrorOverlay(contentMap) {
  return contentMap.overlay_error.value
}

export function createContentList(contentMap) {
  return [
    {
      markdown: contentMap.markdown.value,
    },
  ]
}

export function createErrorContentList(contentMap) {
  return [
    {
      markdown: contentMap.markdown_error.value,
    },
  ]
}

export function createEmptyContentList(contentMap) {
  return []
}
